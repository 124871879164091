import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { DetectorHandler, OfflineHandler } from "../../helpers/connectionHandler";
import { ContentContainer, Icon } from "../../UIElements";
import { AppConfig } from "../../appConfig";
import { Color } from "../../theme";
import NetworkErrorModal from "../networkErrorModal";

import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Disclaimer,
  Socials,
} from "./mainFooter.styled";

const MainFooter = () => {
  const { t } = useTranslation();
  const [isOfflineMode, setIsOfflineMode] = useState(false);
  const socialKeys = Object.keys(AppConfig.socials).filter((key) => AppConfig.socials[key]);

  const setOfflineMode = (event) => {
    event.preventDefault();
    setIsOfflineMode(true);
  }

  return (
    <DetectorHandler render={({ online }) => (
      <Container>
        {isOfflineMode && (
          <OfflineHandler>
            <NetworkErrorModal isOpen />
          </OfflineHandler>
        )}
        <ContentContainer background="semiDarkGray" autoHeight>
          <Nav>
            <NavItem to="/klauzula-informacyjna" target="_blank" rel="noopener noreferrer">
              {t("app.mainFooter.informationDuty")}
            </NavItem>
            <NavItem to="/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">
              {t("app.mainFooter.privacyPolicy")}
            </NavItem>
            <NavItem to="/regulamin" target="_blank" rel="noopener noreferrer">
              {t("app.mainFooter.regulations")}
            </NavItem>
            <NavItem to="/regulamin-szkolen" target="_blank" rel="noopener noreferrer">
              {t("app.mainFooter.trainingRegulations")}
            </NavItem>
            <NavLink href={`mailto:${AppConfig.platformContactEmail}`}>
              {t("app.mainFooter.help")}
            </NavLink>
          </Nav>
          <Disclaimer>
            {t("app.mainFooter.footerDisclaimer_1", { year: dayjs().year() })}
          </Disclaimer>
          <Disclaimer>
            {t("app.mainFooter.footerDisclaimer_2")}
          </Disclaimer>
          {!!socialKeys.length && (
            <Socials>
              {socialKeys.map((key) => (
                <NavLink
                  key={key}
                  target="blank"
                  href={AppConfig.socials[key]}
                  onClick={(event) => !online ? setOfflineMode(event) : null}
                >
                  <Icon icon={`social_${key}`} width={24} size={24} color={Color.black} />
                </NavLink>
              ))}
            </Socials>
          )}
        </ContentContainer>
      </Container>
    )} />
  );
};

export default MainFooter;
