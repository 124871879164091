import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { breakpoints } from "../../UIGlobals";
import { Color, FontWeight } from "../../theme";

export const Container = styled.footer`
  background: ${Color.semiDarkGray};
  width: 100%;
  display: block;

  @media (${breakpoints().maxM}) {
    padding-bottom: calc(70px + env(safe-area-inset-bottom)); 
  }
`;

export const Nav = styled.nav`
  width: 100%;
  padding-bottom: 36px;
  border-bottom: 1px solid #666;

  @media (${breakpoints().maxM}) {
    display: flex;
    flex-direction: column;
    padding-bottom: 26px;
  }
`;

export const navItemStyles = css`
  display: inline-block;
  height: 15px;
  font-size: 1.4rem;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.white};
  text-decoration: none;
  margin-right: 50px;

  @media (${breakpoints().maxM}) {
    display: block;
    margin-bottom: 10px;
    padding: 10px 0;
    height: initial;
    margin-right: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const NavItem = styled(Link)`
  ${navItemStyles}
`;

export const NavLink = styled.a`
  ${navItemStyles}
`;

export const Disclaimer = styled.p`
  display: block;
  padding: 36px 0 0 0;
  font-size: 1.4rem;
  line-height: 1.71;
  text-align: left;
  color: ${Color.footerGray};
`;

export const Socials = styled.div`
  display: flex;

  @media (${breakpoints().minS}) {
    i:only-of-type {
      margin-right: 50px;
    }
  }

  @media (${breakpoints().maxS}) {
    i:only-of-type {
      margin-right: 25px;
    }
  }
  
  @media (${breakpoints().minM}) {
    i:only-of-type {
      margin-right: 0;
    }
  }
`;
