import React from "react";

import LogoSvg from "../../images/logoFull.svg";

import { Wrapper, LogoImg } from "./logo.styled";

export const Logo = () => (
  <Wrapper>
    <LogoImg src={LogoSvg} />
  </Wrapper>
);
