import styled from "styled-components";

import { colors, breakpoints } from "../UIGlobals";
import { Transitions } from "../theme";

const Button = styled.button`
  border-radius: 3px;
  width: ${props =>
  !props.full ? (props.width ? (props.width === "auto" ? "auto" : props.width + "px") : "auto") : "100%"};
  height: ${props => (props.height ? props.height + "px" : "56px")};
  border: ${props => (props.border ? `1px solid ${colors()[props.border]}` : "none")};
  background: ${props => (props.$background ? colors()[props.$background] : colors().brandBasic)};
  color: ${props =>
    props.color
      ? colors()[props.color]
      : props.$background === "white"
        ? colors().black
        : props.$background === "almostWhite"
          ? colors().gray4
          : colors().white};
  font-weight: ${props => (props.$weight ? props.$weight : "700")};
  text-transform: ${props => (props.$text ? props.$text : "uppercase")};
  display: block;
  font-size: ${props => (props.size ? props.size + "rem" : "1.4rem")};
  outline: none !important;
  margin-left: ${props => (props.marginLeft ? props.marginLeft + "px" : "")};
  margin-right: ${props => (props.marginRight ? props.marginRight + "px" : "")};
  margin-top: ${props => (props.marginTop ? props.marginTop : "auto")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom + "px" : "")};
  transition: ${Transitions.custom};
  cursor: pointer;
  padding: ${props => (props.padding ? props.padding : "")};
  .badge{
    fill: ${props => (props.color ? colors()[props.color] : "")};
  }
  &:hover {
    opacity: 0.8;
    color: ${props => (props.hoverColor ? props.hoverColor : "")};
    background: ${props => (props.hoverBackground ? colors()[props.hoverBackground] : "")};
    .badge{
      color: ${props => (props.hoverColor ? props.hoverColor : "")};
      fill: ${props => (props.hoverColor ? props.hoverColor : "")};
      border: none;
    }
  }
  @media (${breakpoints().maxS}) {
    width: ${props => (props.mobileNotFull ? "auto" : "100%")};
    min-width: ${props => (props.mobileNotFull ? "auto" : "60px")};
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "15px")};
  }
  @media (${breakpoints().maxM}) {
    ${props => (props.tabletFull ? "width: 100%" : "")};
  }
`;

export { Button };
