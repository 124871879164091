import styled from "styled-components";

export const Wrapper = styled.div`
  height: 70px;
  overflow: hidden;
  padding-top: 20px;
`;

export const LogoImg = styled.img`
  width: 227px;
  height: auto;
`;
