import styled from "styled-components";

import { hexToRgba } from "../helpers/colors";
import { breakpoints } from "../UIGlobals";
import { Color, Transitions } from "../theme";

export const InnerBold = styled.span`
  font-weight: bold;
`;

export const InfoPopupBoxContainer = styled.div`
  position: fixed;
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  display: ${({ show }) => (show ? "flex" : "none")};
  top: calc(50% - 320px);
  left: 50%;
  margin-top: 50px;
  margin-left: -350px;
  max-height: 650px;
  width: 800px;
  max-width: 100%;
  padding: 40px;
  flex-flow: column;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 103;
  background: ${Color.white};
  font-family: Open Sans;
  line-height: 1.875em;
  font-size: 1.4rem;
  box-shadow: 0 0 1px 1px ${Color.dirtyWhite};

  @media (${breakpoints().maxL}) {
    left: calc(50% - 50px);
  }

  @media (${breakpoints().maxM}) {
    margin-left: auto;
    left: auto;
    max-height: 100%;
    top: auto;
    margin-top: 0;
  }

  @media screen and (max-height:700px) {
    top: 0;
    margin-top: 0;
    height: 100%;
    max-height: 100%;
  }

  @media (${breakpoints().maxS}) {
    top: 0;
    height: auto;
  }
`;

export const InfoPopupInner = styled.div`
  position: relative;
  display: flex;
  flex-flow: ${({ $flexFlow }) => ($flexFlow ? $flexFlow : "row")};
  height: 100%;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  justify-content: space-between;
  background-color: ${({ $transparent }) => hexToRgba(Color.white, $transparent ? 0.95 : 1)};
  padding: ${({ $padding }) => ($padding ? $padding : 0)};
  width: ${({ width }) => (width ? width : "100%")};
  max-height: 450px;
  overflow-y: ${({ overflowY}) => (overflowY ? overflowY : "hidden")};
  transition: ${Transitions.custom};
  z-index: 2;

  @media (${breakpoints().maxL}) {
    width: 100%;
    padding: ${({ $paddingM }) => ($paddingM ? $paddingM : "20px 0")};
    flex-flow: column;
  }

  @media (${breakpoints().maxS}) {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  a {
    text-decoration: none;
    color: ${() => Color.brandBasic};
  }
`;

export const Paragraph = styled.div`
  padding: ${({ $padding }) => ($padding ? $padding : "0 20px 20px")};
  line-height: 1.714;
  overflow-x: visible;
`;

export const InfoPopupHeaderBlack = styled.div`
  font-size: 28px;
  color: ${Color.black};
  padding-right: 21px;
  line-height: 35px;
`;

export const ParagraphContent = styled.div`
  font-size: 13px;
`;

export const InfoPopupLine = styled.hr`
  margin: 20px 0;
  border: 1px solid ${Color.almostWhite};
`;

export const CloseCross = styled.a`
  position: absolute;
  cursor: pointer;
  right: ${({ $right }) => ($right ? $right : "32px")};
  top: ${({ $top }) => ($top ? $top : "32px")};
  width: ${({ size }) => (size ? size : "32px")};
  height: ${({ size }) => (size ? size : "32px")};
  opacity: 0.3;
  margin-left: ${({ $marginLeft }) => ($marginLeft ? $marginLeft : 0)};
  transform:  ${({ transform }) => (transform ? transform : "none")};

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: ${({ $left }) => ($left ? $left : "15px")};
    content: ' ';
    height: ${({ size }) => (size ? size : "32px")};
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 1;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: ${hexToRgba(Color.black, 0.3)};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  display: ${({ show }) => (show ? "block" : "none")};
`;
