import React from "react";
import styled from "styled-components";

import { Media, Transitions } from "../../theme";
import { Icon } from "../../UIElements";

const HamburgerButton = styled.button`
  display: none;

  ${Media.tablet`
    display: block;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translate(0, -50%);
    padding: 12px;
    border: none;
    background-color: transparent;
    margin-left: -12px;
    cursor: pointer;
    transition: ${Transitions.custom};
    &:hover {
      opacity: 0.7;
    }
  `}
`;

const Hamburger = ({ action }) => (
  <HamburgerButton onClick={action}>
    <Icon icon="playlist_files" size={24} color="#a0a0a0" />
  </HamburgerButton>
);

export default Hamburger;
